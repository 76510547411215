.dworkflow-initial-loader-container {
  position: relative;
  min-height: 200px;
  font-size: 20px;
}

.dworkflow-initial-loader {
  min-width: 100%;
  min-height: calc(100% + 1px);
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  text-align: center;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dworkflow-initial-loader-icon {
  border: 5px solid rgba(180, 0, 0, 0.05);
  border-top: 5px solid #b40000;
  padding: 20px;
  animation: spin 1s linear infinite;
  display: inline-block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
