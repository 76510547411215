#navigation_footer,
#navigation_toolbar {
  // padding nur für oberes menü null setzen
  .mat-toolbar li:not(.mat-divider) {
    padding: 0.4em 0;
    display: flex;
    align-items: center;
  }

  .mdc-button {
    padding: 0 16px !important;
  }

  // badge nach unten verschieben, damit es die Menülesite nicht nach oben ausdeht
  .mat-badge-small.mat-badge-above .mat-badge-content {
    top: 4px;
    right: calc(-2em + 14px);

    // Überschreiben der default Werte (16px) durch em Werte, damit der Text Zoombar wird
    width: 2.1em;
    height: 2.1em;
    line-height: 2.1em !important;
    font-size: 9px;
  }

  .mat-badge-medium.mat-badge-above .mat-badge-content {
    // Überschreiben der default Werte (16px) durch em Werte, damit der Text Zoombar wird
    width: 2em;
    height: 2em;
    line-height: 2em !important;
    right: calc(10px - 3em);
  }

  .mat-list-base {
    padding-top: 0;
  }

  .mat-mdc-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-flat-button {
    line-height: unset;
  }

  a,
  button {
    &.mat-mdc-button,
    &.mdc-button,
    &.mat-icon-button,
    &.mat-stroked-button,
    &.mat-flat-button {
      line-height: max(44px, 2.75em) !important;
      height: max(44px, 2.75em) !important;
      display: flex;
      justify-content: flex-start;
    }

    &.mat-mdc-button,
    &.mat-icon-button,
    &.mat-stroked-button {
      color: var(--fontColor);
      &:hover {
        text-decoration: none;
      }
    }

    &.workflow-erstellen {
      background-color: var(--primary);
      &:hover {
        background-color: var(--primaryButtonHover);
      }
    }
  }

  .mat-drawer-backdrop.mat-drawer-shown {
    background-color: unset;
  }
  .mat-mdc-icon-button.mat-mdc-button-base {
    color: var(--fontColor);
    min-width: 44px;
    min-height: 44px;
    aspect-ratio: 1/1;
    height: auto;
    width: auto;
  }
}
