@media only screen and (max-width: 600px) {
  body .cdk-overlay-pane {
    max-width: 98vw !important;
  }
}

body {
  .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
      padding: min(24px, 3vw);
    }

    .close-button .mat-mdc-button > .mat-icon {
      margin: 0;
      justify-content: center;
    }

    [mat-dialog-title] {
      width: 100%;
      display: flex;
      position: relative;
      border-bottom: 1px solid #e5e5e5;
      padding: 0 0 0 0;
      margin: 0 0 15px 0;

      h2 {
        font-weight: normal;
        font-size: 2.4em;
        align-self: flex-start;
        flex: 1 1 100%;
        margin: 0 0 15px 0;
        padding-left: 0;
      }

      .close-button {
        width: auto;
        align-self: flex-start;
        float: right !important;
        padding-right: 0;

        button {
          float: right;
        }
      }
    }

    [mat-dialog-content] {
      margin: 0 0 15px 0;
      padding-bottom: 10px;

      table {
        margin-top: 1em;
      }
    }
    [mat-dialog-actions] {
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid #e5e5e5;
      justify-content: flex-end;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding-right: 0;
    }
  }

  .cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane {
    overflow-y: auto;
  }
}

.modal-small-size {
  width: 50em;
}

.modal-medium-size {
  width: 80em;
}

.modal-large-size {
  width: 100em;
}
