.keyword {
  color: rgb(241, 118, 94);
  font-weight: bold;
}

.actions {
  position: relative;
  text-align: right;
  padding: 10px;
}

.actions i {
  background-size: contain;
  display: inline-block;
  height: 15px;
  width: 15px;
  vertical-align: -0.25em;
}

.other h2 {
  font-size: 18px;
  color: #444;
  margin-bottom: 7px;
}

.other a {
  color: #777;
  text-decoration: underline;
  font-size: 14px;
}

.other ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.table-cell-action-button-container {
  position: absolute;
}

.table-cell-action-button {
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  position: relative;
  border-radius: 15px;
  color: #222;
  display: inline-block;
  cursor: pointer;
}

i.chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 8px;
  width: 8px;
}

.action-button {
  background-color: #eee;
  border: 0;
  padding: 8px 12px;
  position: relative;
  margin-left: 5px;
  border-radius: 15px;
  color: #222;
  display: inline-block;
  cursor: pointer;
}

.action-button:hover {
  background-color: #ddd;
  color: #000;
}

.action-button-mic.active {
  animation: mic-pulsate-color 3s infinite;
}
button.action-button:disabled {
  opacity: 0.6;
  background: #eee;
  cursor: not-allowed;
}

@keyframes mic-pulsate-color {
  0% {
    background-color: #ffdcdc;
  }
  50% {
    background-color: #ff8585;
  }
  100% {
    background-color: #ffdcdc;
  }
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}
