@use '@angular/material' as mat;
@use 'sass:map';

// Theme für DateTimePicker für Bannerverwaltung
@use '@ng-matero/extensions' as mtx;

@import '../typography.scss';

// eigener Theme für dWorkflow. Wurde auf Grundlage der Color #4e0000 mit dem Tool https://materialtheme.arcsine.dev/ erstellt.

$primary: #4e0000;

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.74);
$dark-disabled-text: rgba($dark-primary-text, 0.68);
$dark-outline: rgba($dark-text, 0.12);
$dark-dividers: rgba($dark-primary-text, 0.32);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.9);
$light-disabled-text: rgba($light-primary-text, 0.7);
$light-outline: rgba($light-text, 0.3);
$light-dividers: rgba($light-primary-text, 0.32);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #ffffff;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $primary,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #131313;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: #000000,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-background,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $primary,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

$mat-dWorkflow: (
  main: #4e0000,
  lighter: #720000,
  darker: #290000,
  200: #4e0000,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$primary-palette: mat.m2-define-palette($mat-dWorkflow, main, lighter, darker);

$mat-accent: (
  main: #494949,
  lighter: #707070,
  darker: #222222,
  200: #494949,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$accent-palette: mat.m2-define-palette($mat-accent, main, lighter, darker);

// The warn palette is optional (defaults to red).
$warn-palette: mat.m2-define-palette(mat.$m2-red-palette);

$mat-primary-dark: (
  main: #ffb3b3,
  lighter: #fff0f0,
  darker: #dd9d9d,
  200: #ffb3b3,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$dark-primary-palette: mat.m2-define-palette($mat-primary-dark, main, lighter, darker);

// Create the theme object
$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
    ),
    typography: get-dworkflow-typography(),
  )
);

$light-color: map.get($theme, 'color');
$light-colorBackground: map.get($light-color, 'background');
$light-color: map.set($light-color, 'background', $mat-light-theme-background);
$theme: map.set($theme, 'color', $light-color);

$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $dark-primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
    ),
    typography: get-dworkflow-typography(),
  )
);
$dark-color: map.get($dark-theme, 'color');
$dark-colorBackground: map.get($dark-color, 'background');
$dark-color: map.set($dark-color, 'background', $mat-dark-theme-background);
$dark-theme: map.set($dark-theme, 'color', $dark-color);

$primary-contrast: $light-primary-text;

@mixin light-mode-variables {
  --accent: #{map-get($accent-palette, main)};
  --primaryContrast: #{$primary-contrast};
  --primaryLighter: #{map-get($primary-palette, lighter)};
  --primaryDarker: #{map-get($primary-palette, darker)};
  --primaryText: #{map-get($primary-palette, main)};
  --fontColor: #{$dark-primary-text};
  --disabledFontColor: #{$dark-disabled-text};
  --outlines: #{$dark-primary-text};
  --outlinesLight: #{$dark-outline};
  --background: #{$light-background};
  --navbarDefaultFontColor: #{$dark-primary-text};
  --lighterAlternateBgColor: #ededed;
  --placeholderColor: #{$dark-disabled-text};
  --dividers: #{$dark-dividers};
  --darkerAlternateBgColor: #f9f9f9;
  --erfolgreichRowColor: #e0ffe0;
  --abgelehntRowColor: #ffb2b2;
  --rueckfrageRowColor: #ffe4b2;
  --verfuegungRowColor: #ffe4b2;
  --formularErstellungRowColor: #ffe4b2;
  --wfStartenInfoTextColor: #685027;
  --wfStartenInfoBackgroundColor: #fcf8e3;
  --wfInfoTextColor: #224f67;
  --wfInfoBackgroundColor: #d9edf7;
  --wfAbgeschlossenTextColor: #2e562e;
  --wfAbgeschlossenBackgroundColor: #dff0d8;
  --wfStorniertTextColor: #3c0000;
  --wfStorniertBackgroundColor: #e19191;
  --haftnotiz: #eae672;
  --redAlertColor: #701111;
  --redAlertButtonColor: #ab0202;
  --hover: #{$dark-bg-alpha-4};
  --disabledBackgroundColor: #b0b0b0;
}

@mixin dark-mode-variables {
  --accent: #{map-get($accent-palette, main)};
  --primaryContrast: #{$primary-contrast};
  --primaryLighter: #{map-get($dark-primary-palette, lighter)};
  --primaryDarker: #{map-get($dark-primary-palette, darker)};
  --primaryText: #{map-get($dark-primary-palette, main)};
  --fontColor: #{$light-primary-text};
  --disabledFontColor: #{$light-disabled-text};
  --outlines: #{$light-primary-text};
  --outlinesLight: #{$light-outline};
  --background: #{$dark-background};
  --navbarDefaultFontColor: #{$light-primary-text};
  --lighterAlternateBgColor: #272727;
  --placeholderColor: #{$light-disabled-text};
  --dividers: #{$light-dividers};
  --darkerAlternateBgColor: #1d1d1d;
  --erfolgreichRowColor: #004900;
  --abgelehntRowColor: #6c0000;
  --rueckfrageRowColor: #64410c;
  --verfuegungRowColor: #64410c;
  --formularErstellungRowColor: #64410c;
  --wfStartenInfoTextColor: #685027;
  --wfStartenInfoBackgroundColor: #fcf8e3;
  --wfInfoTextColor: #224f67;
  --wfInfoBackgroundColor: #d9edf7;
  --wfAbgeschlossenTextColor: #2e562e;
  --wfAbgeschlossenBackgroundColor: #d9edf7;
  --wfStorniertTextColor: #3c0000;
  --wfStorniertBackgroundColor: #e19191;
  --haftnotiz: #411402;
  --redAlertColor: #fd8181;
  --redAlertButtonColor: #ab0202;
  --hover: #{$light-bg-alpha-4};
  --disabledBackgroundColor: #4e4e4e;
}

// Ab hier folgt das eigentliche Setzen des Themes, alles davor ist nur Vorbereitung

@mixin highContrastTheme {
  .high-contrast-theme {
    --primary: #{map-get($primary-palette, main)};
    --primaryButtonHover: #{map-get($primary-palette, darker)};
    --warn: #{map-get($warn-palette, main)};

    @include mtx.all-component-themes($theme);
    @include mat.all-component-themes($theme);
    @include mat.all-component-colors($theme);
    @include mat.all-component-typographies($theme);
    @include light-mode-variables();

    @media (prefers-color-scheme: dark) {
      @include mtx.all-component-themes($dark-theme);
      @include mat.all-component-themes($dark-theme);
      @include mat.all-component-colors($dark-theme);
      @include dark-mode-variables();
      @include mat.all-component-typographies($dark-theme);
    }

    &.dark-mode-active {
      @include mtx.all-component-themes($dark-theme);
      @include mat.all-component-themes($dark-theme);
      @include mat.all-component-colors($dark-theme);
      @include dark-mode-variables();
      @include mat.all-component-typographies($dark-theme);
    }

    &.light-mode-active {
      @include mtx.all-component-themes($theme);
      @include mat.all-component-themes($theme);
      @include mat.all-component-colors($theme);
      @include light-mode-variables();
      @include mat.all-component-typographies($theme);
    }
  }
}
