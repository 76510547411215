// Folgende Klassen werden innerhalb der Formly-Komponenten verwendet
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1em;
}

.flex-column {
  flex-direction: column;
}

.flex-row-bottom-aligned {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 1em;
}

.flex-item-auto {
  flex: 1 1 auto;
}

.flex-grow {
  flex-grow: 1;
}

.formly-background-dark-gray {
  background-color: var(--disabledBackgroundColor);
}

.formly-background-light-gray {
  background-color: var(--darkerAlternateBgColor);
}

.full-width {
  width: 100%;
}

// 6em passt gut für ein Field vom Type number
.max-width-6 {
  max-width: 6em;
}

// 12em passt gut für ein Field vom Type datepicker
.max-width-12 {
  max-width: 12em;
}

.min-width-20 {
  min-width: 20em;
}

.min-width-25 {
  min-width: 25em;
}

.min-width-30 {
  min-width: 30em;
}

.min-width-35 {
  min-width: 35em;
}

.min-width-40 {
  min-width: 40em;
}

.min-width-45 {
  min-width: 45em;
}

.min-width-50 {
  min-width: 50em;
}
